<template>
  <div v-padding:top="46">
    <van-nav-bar title="监测验收" fixed left-arrow @click-left="goBack">
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group>
        <van-field
          name="deviceName"
          v-model="formData.deviceName"
          label="设备名称"
          placeholder="请填写设备名称"
          :rules="[{ required: true, message: '请填写设备名称' }]"
        />
        <van-field
          name="deviceModel"
          v-model="formData.deviceModel"
          label="设备型号"
          placeholder="请填写设备型号"
        />
        <van-field
          name="acceptanceUnit"
          v-model="formData.acceptanceUnit"
          label="验收单位"
          placeholder="请填写验收单位"
        />
        <!-- :rules="[{ required: true, message: '请填写验收单位' }]" -->
        <van-field
          readonly
          clickable
          name="acceptanceTime"
          v-model="formData.acceptanceTime"
          label="验收时间"
          placeholder="请选择验收时间"
          @click="calendarShow = true"
        />
        <!-- :rules="[{ required: true, message: '请选择验收时间' }]" -->
        <van-field name="uploader" label="附件">
          <template #input>
            <van-uploader
              v-model="file"
              :max-count="1"
              :max-size="10240000"
              :after-read="afterRead"
              :before-read="beforeRead"
              @click-preview="clickPrev"
              @delete="deleteFile"
            />
          </template>
        </van-field>
      </van-cell-group>
      <van-row>
        <van-col span="24">
          <van-button
            block
            type="primary"
            native-type="submit"
            :loading="submitLoading"
          >
            保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-calendar
      v-model="calendarShow"
      type="single"
      :default-date="defaultDate"
      @confirm="onConfirm"
      :allow-same-day="true"
      :style="{ height: '62%' }"
      color="#378aed"
    />
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  components: {},
  props: {},
  data() {
    return {
      enterpriseId: localStorage.getItem("enterpriseId"),
      formData: {
        deviceName: "", // 设备名称
        deviceModel: "", // 设备型号
        acceptanceUnit: "", // 验收单位
        acceptanceTime: "", // 验收时间
      },
      file: [],
      uploadedFile: [],
      fileType: [],
      submitLoading: false,
      calendarShow: false,
      defaultDate: this.$moment().toDate(),
      calendarType: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    if (this.$route.query.operate == "edit") {
      this.getsMonitorinspectedInfo();
    }
  },
  methods: {
    beforeRead(file) {
      const flag = new RegExp(
        "[`~!@#$^&*=|{}':;',\\[\\]<>《》/?~！@#￥……&*——|{}【】‘；：”“'。，、？%]"
      );
      if (flag.test(file.name)) {
        Toast.fail({
          message: "文件名不能包含特殊字符",
        });
        return false;
      }
      return true;
    },
    afterRead(file) {
      this.fileType = this.fileType.concat(file.file.name);
      file.status = "uploading";
      const data = new FormData();
      data.append("file", file.file);
      this.$api.setting.uploadFile(data).then((res) => {
        this.uploadedFile = this.uploadedFile.concat(res.data.data[0].id);
        file.status = "done";
      });
    },
    clickPrev(file) {
      const { name } = "name" in file ? file : file.file;
      const suffixIndex = name.lastIndexOf(".");
      const suffix = name.substring(suffixIndex + 1).toUpperCase();
      if (
        suffix != "BMP" &&
        suffix != "JPG" &&
        suffix != "JPEG" &&
        suffix != "PNG" &&
        suffix != "GIF"
      ) {
        Toast.fail("不支持在线查看，请到PC端下载");
      }
    },
    deleteFile(file, detail) {
      file.status = "uploading";
      const { index } = detail;
      const fileId = this.uploadedFile[index];
      const typeId = this.fileType[index];
      const data = {
        id: fileId,
      };
      this.$api.common.getDeleteFile(data).then((res) => {
        if (res.data.state == 0) {
          this.uploadedFile = this.uploadedFile.filter((item) => {
            return item != fileId;
          });
          this.fileType = this.fileType.filter((item) => {
            return item != typeId;
          });
          file.status = "done";
        }
      });
    },
    // 新增排污许可证
    addMonitorinspectedInfo() {
      this.submitLoading = true;
      const data = {
        enterpriseId: this.enterpriseId,
        deviceName: this.formData.deviceName,
        deviceModel: this.formData.deviceModel,
        acceptanceUnit: this.formData.acceptanceUnit,
        acceptanceTime: this.formData.acceptanceTime
          ? this.$moment(this.formData.acceptanceTime).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : "",
        fileIds: this.uploadedFile,
      };
      this.$api.setting
        .addSysMonitorAcceptance(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.success("新增成功");
            this.goBack();
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    // 修改排污许可证
    editMonitorinspectedInfo() {
      this.submitLoading = true;
      const data = {
        enterpriseId: this.enterpriseId,
        id: this.formData.id,
        deviceName: this.formData.deviceName,
        deviceModel: this.formData.deviceModel,
        acceptanceUnit: this.formData.acceptanceUnit,
        acceptanceTime: this.formData.acceptanceTime
          ? this.$moment(this.formData.acceptanceTime).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : "",
        fileIds: this.uploadedFile,
      };
      this.$api.setting
        .postEditSysMonitorAcceptance(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.success("修改成功");
            this.goBack();
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    onSubmit() {
      if (this.$route.query.operate == "add") {
        this.addMonitorinspectedInfo();
      } else if (this.$route.query.operate == "edit") {
        this.editMonitorinspectedInfo();
      }
    },
    // 日历确定
    onConfirm(date) {
      this.formData.acceptanceTime = this.$moment(date).format("YYYY-MM-DD");
      this.calendarShow = false;
    },
    // 获取排污许可证详情
    getsMonitorinspectedInfo() {
      const data = {
        id: this.$route.query.id,
      };
      this.$api.setting.getSysMonitorAcceptanceById(data).then((res) => {
        if (res.data.state == 0) {
          this.formData = {
            enterpriseId: res.data.data.enterpriseId,
            id: res.data.data.id,
            deviceName: res.data.data.deviceName,
            deviceModel: res.data.data.deviceModel,
            acceptanceUnit: res.data.data.acceptanceUnit,
            acceptanceTime: res.data.data.acceptanceTime
              ? this.$moment(res.data.data.acceptanceTime).format("YYYY-MM-DD")
              : "",
          };
          if (res.data.data.fileVoList.length > 0) {
            const tempArr = [];
            const tempFileIds = [];
            const fileArr = res.data.data.fileVoList || [];
            fileArr.forEach((element) => {
              tempArr.push({
                name: element.fileName,
                url: element.url,
                id: element.id,
              });
              tempFileIds.push(element.id);
            });
            this.file = tempArr;
            this.uploadedFile = tempFileIds;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
